function cssLoaded(callback, timeout) {
    if (document.querySelector("[data-loadcss]") !== null) {
        let wait = setInterval(function () {
            if (getComputedStyle(document.body)["margin-left"] === "0px") {
                clearInterval(wait);
                setTimeout(function () {
                    callback();
                }, timeout);
            }
        }, 1);
    } else {
        setTimeout(function () {
            callback();
        }, timeout);
    }
}