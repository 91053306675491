if (typeof Swup !== "undefined") {
    const swup = new Swup({
        containers: ['#layout_main', '#layout_header', '#layout_footer', '#layout_social']
    });

    swup.on('clickLink', function () {
        document.body.classList.remove("overflow--hidden");

        if (document.querySelector(".drawer-active") !== null) {
            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").hide()
        }

        if (document.querySelector(".lib--dialog") !== null) {
            LibDialog.hide();
        }
    });

    document.addEventListener('swup:animationOutDone', () => {
        window.scrollTo(0, 0);

        if (typeof window.swupActive === "undefined") {
            window.swupActive = true;
        }
    });

    swup.on('contentReplaced', function () {
        const content = new DOMParser().parseFromString(swup.cache.getCurrentPage().originalContent, 'text/html')

        content.documentElement.querySelectorAll('[data-lib-swup-replace]').forEach(element => {
            const tag = document.querySelector(`[data-lib-swup-replace=${element.dataset.libSwupReplace}]`)
            const placement = element.closest('head') ? document.head : document.body

            if (element.nodeName === 'SCRIPT') {
                const script = document.createElement('script')

                ;[...element.attributes].forEach(attribute => {
                    if (attribute.specified) {
                        if (attribute.name.indexOf('type') === -1) {
                            script.setAttribute(attribute.name, attribute.value)
                        }
                    }
                })

                script.innerHTML = element.innerHTML

                placement.appendChild(script)
            } else {
                if (tag !== null) {
                    tag.outerHTML = element.outerHTML
                } else {
                    placement.insertAdjacentHTML('beforeend', element.outerHTML)
                }
            }
        })

        if (typeof locomotive !== "undefined") {
            locomotive.destroy();
            document.getElementById("layout_scroll").removeAttribute("style");
            if (document.querySelector("[data-no-locomotive]") === null) {
                locomotive.init();
            }
        }
        window.scrollTo(0, 0);

        swup.options.containers.forEach((selector) => {
            loadStimulus(document.querySelector(selector));
        });

        LibAnchor.init();

        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function (config) {
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': window.location.pathname + window.location.search,
                'virtualPageTitle': document.title
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            importScript("https://c.imedia.cz/js/retargeting.js");
        }

        if (typeof LibCookieConsent !== "undefined") {
            LibCookieConsent.init();
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");
    });
}
