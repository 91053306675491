application.register("part_ui_select", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element,
            select = element.querySelector("select"),
            option = [...element.querySelectorAll("[data-option]")];

        element.addEventListener("click", () => {
            if (!element.classList.contains("state--focus")) {
                element.classList.add("state--focus");
            } else {
                element.classList.remove("state--focus");
            }

            element.addEventListener("blur", function e() {
                element.classList.remove("state--focus");
                element.removeEventListener("blur", e);
            }, true);
        }, true);

        select.addEventListener("change", () => {
            element.classList.remove("state--invalid", "state--valid");

            if (select.checkValidity()) {
                element.classList.add("state--valid");
            } else {
                element.classList.add("state--invalid");
            }

            if (select.value === "") {
                element.classList.remove("state--active");
            } else {
                element.classList.add("state--active")
            }
        });

        if (option[0] !== null) {
            option.map((option) => {
                option.addEventListener("click", () => {
                    select.value = option.getAttribute("data-option");
                    select.dispatchEvent(new Event('change', { 'bubbles': true }));
                });
            });
        }

        if (select.value === "") {
            element.classList.remove("state--active");
        } else {
            element.classList.add("state--active")
        }
    }

    toggle(e) {
        // e.currentTarget.classList.add("state--focus");
        // e.currentTarget.querySelector("[aria-hidden]").setAttribute("aria-hidden", "false");



        // $(this).toggleClass("state--focus").focusin();
        // $(this).find("[aria-hidden]").attr("aria-hidden", false);
        // $(this).one("blur",function() {
        //     $(this).removeClass("state--focus");
        //     $(this).find("[aria-hidden]").attr("aria-hidden", true);
        // });
    }
});