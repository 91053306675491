application.register("part_ui_wsw", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;

        Array.from(element.querySelectorAll("table")).filter((table) => {
            self.wrap(table, new DOMParser().parseFromString(`<div class="elm_table"></div>`, 'text/html').body.firstChild);
        });

        Array.from(element.querySelectorAll("iframe")).filter((iframe) => {
            if (iframe.src.match(/youtube\.com/i)) {
                self.wrap(iframe, new DOMParser().parseFromString(`<div class="elm_video"></div>`, 'text/html').body.firstChild);
            }
        });
    }

    wrap(el, wrapper) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }
});