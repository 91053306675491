application.register("part_form", class extends Stimulus.Controller {
    connect() {
        let element = this.element;

        if (typeof HTMLFormElement.prototype.reportValidity !== "undefined") {
            element.addEventListener('submit', function (event) {
                if (element.reportValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                [...element.querySelectorAll(".part_ui_input")].map((element) => {
                    application["getControllerForElementAndIdentifier"](element, "part_ui_input").validateInput(element, true)
                });

                [...element.querySelectorAll(".part_ui_select:not(.state--active) select[required]")].map((element) => {
                    element.parentNode.classList.add("state--invalid")
                });
            }, false);
        }
    }
});